import { render, staticRenderFns } from "./ArchiveLearners.vue?vue&type=template&id=b89514ea&"
import script from "./ArchiveLearners.vue?vue&type=script&lang=js&"
export * from "./ArchiveLearners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports